import React from "react";
import { Navigation } from "../components/global/nav"
import { Footer } from '../components/global/footer'
import "../components/local/sign-up/styles.css"
import { Home as SignUp } from '../components/local/sign-up/home'
import SEO from "../components/global/seo";
import CookiePrompt from "../components/global/cookies-prompt";
import Breadcrumbs from "../components/global/breadcrumbs";
import { Helmet } from "react-helmet";
import { window } from "browser-monads";


const Join = () => {
    return (
        <main>
            <Helmet>
            <script>
                {`!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '978576809479414');
                fbq('track', 'PageView');`}
            </script>
            <script type="text/javascript">{`(function(w,s){var e=document.createElement("script");e.type="text/javascript";e.async=true;e.src="https://cdn-eu.pagesense.io/js/smartplan/9329848e73044127948e17cbcbff5ea9.js";var x=document.getElementsByTagName("script")[0];x.parentNode.insertBefore(e,x);})(window,"script")`}</script>
            </Helmet>
            <SEO
                title="Sign up to Smart Plan"
                slug="join"
                metadescription="Sign up to one of Smart Plan's custom home service plans to ensure your boiler, appliances and more are always looked after."
                image=""
                titleTemplate="%s"
            />
            <CookiePrompt/>
            <Navigation />

                <div className="standard-title-container default">
                    <div className="standard-title-inner-container body-inner-width">
                        <div className="standard-title-inner-content">
                            <h1 id="title">Get covered through Smart Plan</h1>
                            <p>Life happens, things can't run smoothly all the time, but once you're covered by Smart Plan, you don't have to worry if something goes wrong in your home. We'll work tirelessly to keep your home warm and cosey.</p>
                        </div>
                    </div>
                </div>

                <div className="standard-container signup">
                    <div className="standard-inner-container body-inner-width">
                        <Breadcrumbs 
                            location="join"
                        />

                        {/*<SignUp/>*/}

                        <h2>This page is down for maintanence</h2>
                        <p>To sign up, you can call <a href="tel:+443337726247">0333&nbsp;772&nbsp;6247</a> and speak to one of our team</p>

                        <br/>

                        <h3>Frequently Asked Questions (FAQs)</h3>

                        {/* FAQ 1 */}
                        <input
                            className="accordion-trigger invisible"
                            type="radio"
                            id="FAQ1"
                            name="faqs"
                        />

                        <label htmlFor="FAQ1" className="standard-accordion">
                            <div className="standard-accordion-header">
                                What is Smart Plan?
                            </div>
                        </label>

                        <div className="standard-accordion-body body">
                            <p>Smart Plan is a <u>nationwide</u> home emergency provider, with <strong>over 15,000 customers</strong> that have put their trust into us to keep their house a home.</p>

                            <p>We have a network of over 1,000 Gas Safe qulified engineer across England, Scotland, Wales and Northern Ireland.</p>

                            <p>We specialise in <u>customized plans</u> that ensure that customers are not paying for parts of plans that they do not use. We don't believe in one-size-fits-all, you should get to <strong>choose what you do and don't want!</strong></p>
                        </div>

                        {/* FAQ 2 */}
                        <input
                            className="accordion-trigger invisible"
                            type="radio"
                            id="FAQ2"
                            name="faqs"
                        />

                        <label htmlFor="FAQ2" className="standard-accordion">
                            <div className="standard-accordion-header">
                                How fast will Smart Plan come and fix my problem?
                            </div>
                        </label>

                        <div className="standard-accordion-body body">
                            <p>Between 2020-2022, the majority of customers recieved an engineer to their property <strong>within 48 hours</strong> from first letting us know about their breakdown/issue.</p>
                        </div>

                        {/* FAQ 3 */}
                        <input
                            className="accordion-trigger invisible"
                            type="radio"
                            id="FAQ3"
                            name="faqs"
                        />

                        <label htmlFor="FAQ3" className="standard-accordion">
                            <div className="standard-accordion-header">
                                How does pricing of the plans work?
                            </div>
                        </label>

                        <div className="standard-accordion-body body">
                            <p>With Smart Plan, you only pay for the parts of the policy that you want to add. We specialize in fully customized plans that put you in the drivers seat.</p>

                            <p>Each area of the home you would like to cover is added to you final price, priced <strong>from as little as £2</strong> per coverage area, per month.</p>
                        </div>

                        {/* FAQ 4 */}
                        <input
                            className="accordion-trigger invisible"
                            type="radio"
                            id="FAQ4"
                            name="faqs"
                        />

                        <label htmlFor="FAQ4" className="standard-accordion">
                            <div className="standard-accordion-header">
                                Can I cancel my plan at any time?
                            </div>
                        </label>

                        <div className="standard-accordion-body body">
                            <p>All of the Smart Plan products available online are <strong>30-day rolling plans</strong>, which mean that if you are not using your plan and you would like to cancel, you can do so <u>at any time</u> without penalty. (Subject to <a href="https://smart-plan.com/documents/cover_plan_t&cs.pdf" target="_blank">terms & conditions</a>)</p>
                        </div>

                        {/* FAQ 5 */}
                        <input
                            className="accordion-trigger invisible"
                            type="radio"
                            id="FAQ5"
                            name="faqs"
                        />

                        <label htmlFor="FAQ5" className="standard-accordion">
                            <div className="standard-accordion-header">
                                Will my price change or is it fixed?
                            </div>
                        </label>

                        <div className="standard-accordion-body body">
                            <p>As per our <a href="https://smart-plan.com/documents/cover_plan_t&cs.pdf" target="_blank">terms & conditions</a>, there are <strong>no price increases</strong>. Your price will remain the same year in, year out.</p>
                        </div>

                        {/* FAQ 6 */}
                        <input
                            className="accordion-trigger invisible"
                            type="radio"
                            id="FAQ6"
                            name="faqs"
                        />

                        <label htmlFor="FAQ6" className="standard-accordion">
                            <div className="standard-accordion-header">
                                What is / isn't included in the plans?
                            </div>
                        </label>

                        <div className="standard-accordion-body body">
                            <p>Naturally, there are many inclusions and many exclusions, due to the complex nature of each coverage area in your home. To see a full list of inclusions and exclusions, please visit our <a href="https://smart-plan.com/documents/cover_plan_t&cs.pdf" target="_blank">terms & conditions</a>.</p>
                        </div>
                    </div>
                </div>

            <Footer />
        </main>
    )
}

export default Join