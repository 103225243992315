import React from "react";

const products = [
   {
      product_text: "Boiler",
      product_code: "boiler",
      description: "Cover your boiler, central heating and all the things that keep you nice and warm.",
      category: "boiler",
      modal_description: [
         <React.Fragment>
            <p>Boiler cover is a way of covering your boiler against any unexpected breakdowns.</p>

            <p>If something goes wrong with your boiler, <u>we'll come and fix it</u> for you.</p>

            <p>All parts and labour and included, so you can relax knowing that Smart Plan have got you covered.</p>
         </React.Fragment>
      ]
   },
   {
      product_text: "Plumbing",
      product_code: "plumbing",
      description: "When things get clogged and start leaking, let us come to the rescue.",
      category: "home",
      modal_description: [
         <React.Fragment>
            <p>Plumbing cover is way of covering the plumbing in your home from any unexpected issues.</p>

            <p>If you are experiencing leaks, blockages or damages in your pipework, <u>we'll come and fix it</u> for you.</p>

            <p>All parts and labour and included, so you can relax knowing that Smart Plan have got you covered.</p>
         </React.Fragment>
      ]
   },
   {
      product_text: "Electrical",
      product_code: "electrical",
      description: "Who turned off the lights? Electrical wiring can be dangerous, let us take care of it.",
      category: "home",
      modal_description: [
         <React.Fragment>
            <p>Electrical cover is a way of covering certain electrical equipment in your home from unexpected issues.</p>

            <p>If you are experiencing issues with equipment such as fuse boxes, switches, sockets or alarms <u>we'll come and fix it</u> for you.</p>

            <p>All parts and labour and included, so you can relax knowing that Smart Plan have got you covered.</p>
         </React.Fragment>
      ]
   },
   {
      product_text: "Appliances",
      product_code: "appliances",
      description: "Nowadays, we can't live without our tech. With Smart Plan, you don't have to!",
      category: "appliance",
      modal_description: [
         <React.Fragment>
            <p>Appliance cover is a way of protecting appliances and technology in your home against unexpected issues.</p>

            <p>If any of your household appliances, such as your washing machine, fridge, dishwasher or television breakdown, we have cover available to that <u>we'll come and fix it</u> for you.</p>
            
            <p>All parts and labour and included, so you can relax knowing that Smart Plan have got you covered.</p>
         </React.Fragment>
      ]
   },
   {
      product_text: "Pests",
      product_code: "pests",
      description: "Uninvited guest? Don't worry, we'll take care of it so you don't have to.",
      category: "home",
      modal_description: [
         <React.Fragment>
            <p>Pest cover protects your home from uninvited guests, such as wasps, mice or rats.</p>

            <p>If you've ever had an infestation or hive issue in your home, you'll know how expensive it can be to get them removed. With Smart Plan, you don't have to worry, <u>we'll sort it out</u> for you.</p>

            <p>All parts and labour and included, so you can relax knowing that Smart Plan have got you covered.</p>
         </React.Fragment>
      ]
   },
   {
      product_text: "Drainage",
      product_code: "drainage",
      description: "Drains, guttering, plug holes, it can all get a bit messy. Let us take care of it.",
      category: "home",
      modal_description: [
         <React.Fragment>
            <p>Drainage cover is a way of covering the drainage in your home from any unexpected issues.</p>

            <p>If you experience any blockages, that require rodding, jetting or most blockages that you aren't able to fix yourself, <u>we'll come and fix it</u> for you.</p>
            
            <p>All parts and labour and included, so you can relax knowing that Smart Plan have got you covered.</p>
         </React.Fragment>
      ]
   },
   {
      product_text: "Security",
      product_code: "security",
      description: "Feeling safe and secure in your home could not be more important. We'll ensure you do.",
      category: "home",
      modal_description: [
         <React.Fragment>
            <p>Security cover is a way of ensuring that your home is always safe and secure.</p>

            <p>Ever lost your key, it snapped in the lock or you needed a window boarding up, these are some of the ways that Smart Plan will help you keep your home secure if you ever need us.</p>

            <p>All parts and labour and included, so you can relax knowing that Smart Plan have got you covered.</p>
         </React.Fragment>
      ]
   }
]

export default products;